import { Suspense, lazy, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
import { Protector } from "./helpers/userData";
import AppBanner from "./components/AppBanner";

// Toggle admin protection here
const ADMIN_PROTECTION_ENABLED = false;

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// shop page
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgottenPassword = lazy(() => import("./pages/other/ForgottenPassword"));
const DynamicPage = lazy(() => import("./pages/other/DynamicPage"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const ThankYou = lazy(() => import("./pages/other/ThankYouPage"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const Logout = lazy(() => import("./pages/other/Logout"));

const AppDownload = lazy(() => import("./pages/other/AppDownload"));

const shouldShowBanner = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// Admin Login Component
const AdminLogin = ({ onLogin }) => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (credentials.username === 'admin' && credentials.password === 'your-secret-password') {
      localStorage.setItem('adminAuthenticated', 'true');
      onLogin();
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div className="vh-100 d-flex align-items-center justify-content-center bg-light">
      <form onSubmit={handleSubmit} className="bg-white p-4 rounded shadow" style={{width: '400px'}}>
        <h2 className="h4 mb-4 text-center">Admin Access</h2>
        <div className="mb-3">
          <input
            type="text"
            placeholder="Username"
            className="form-control"
            onChange={(e) => setCredentials({...credentials, username: e.target.value})}
          />
        </div>
        <div className="mb-3">
          <input
            type="password"
            placeholder="Password"
            className="form-control"
            onChange={(e) => setCredentials({...credentials, password: e.target.value})}
          />
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Login
        </button>
      </form>
    </div>
  );
};

// Protected Routes Wrapper
const ProtectedApp = ({ children }) => {
  if (!ADMIN_PROTECTION_ENABLED) return children;
  const isAuthenticated = localStorage.getItem('adminAuthenticated') === 'true';
  return isAuthenticated ? children : <Navigate to="/admin-login" />;
};

const App = () => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(
    localStorage.getItem('adminAuthenticated') === 'true'
  );
  
  return (
      <Router>
        <ScrollToTop>
          <Suspense>
            <Routes>
            {ADMIN_PROTECTION_ENABLED && (
              <Route 
                path="/admin-login" 
                element={
                  isAdminAuthenticated ? 
                  <Navigate to="/" /> : 
                  <AdminLogin onLogin={() => setIsAdminAuthenticated(true)} />
                } 
              />
            )}

            {/* All Other Routes */}
            <Route
              path="/*"
              element={
                <ProtectedApp>
                  <Routes>
                    <Route path="/" element={<HomeFashion/>} />
                    <Route path="/home-fashion" element={<HomeFashion/>} />
                    <Route path="/products" element={<ShopGridStandard/>} />
                    <Route path="/product/:id" element={<Product />} />
                    <Route path="/page/:pageName" element={<DynamicPage/>} />
                    <Route path="/about" element={<About/>} />
                    <Route path="/contact" element={<Contact/>} />
                    <Route path="/my-account/*" element={<Protector Component={MyAccount} />} />
                    <Route path="/connexion-inscription" element={<LoginRegister/>} />
                    <Route path="/mot-de-passe-oublie" element={<ForgottenPassword/>} />
                    <Route path="/logout" element={<Logout/>} />
                    <Route path="/app" element={<AppDownload/>} />
                    <Route path="/cart" element={<Cart/>} />
                    <Route path="/wishlist" element={<Wishlist/>} />
                    <Route path="/compare" element={<Compare/>} />
                    <Route path="/checkout" element={<Protector Component={Checkout} />} />
                    <Route path="/thank-you" element={<Protector Component={ThankYou} />} />
                    <Route path="*" element={<NotFound/>} />
                  </Routes>
                </ProtectedApp>
              }
            />
            </Routes>
            {/* <ToastContainer /> */}
          {shouldShowBanner && <AppBanner />}
          </Suspense>
        </ScrollToTop>
      </Router>
  );
};

export default App;